import Loading from '@src/components/loading'
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react'
import { FaFileImage as AttachedImageIcon } from 'react-icons/fa'
import ButtonClose from '../button-close.dialog'
import { ApiService } from '@src/services/api/api.service'

import { LightZoom } from './light.zoom'
import { MIN_ZOOM, ZOOM_INCREMENT } from '@src/utils/zoom'

import InfiniteViewer from 'react-infinite-viewer'
import { zoomLight } from '@src/AppState'
import { useRecoilState } from 'recoil'

type Props = {
    commentKey: string
    commentText: string
}

export default function LightBox({ commentKey, commentText }: Props) {
    const apiService = new ApiService()
    const dialogRef = useRef<HTMLDialogElement | null>(null)

    const viewer = useRef<InfiniteViewer>({} as InfiniteViewer)

    const imgRef = useRef<HTMLImageElement | null>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [lightZoom, setLightZoom] = useRecoilState(zoomLight)

    const handlePress = (evt: MouseEvent<HTMLButtonElement>) => {
        if (!dialogRef.current) return

        evt.preventDefault?.()
        const dialog = dialogRef.current

        if (dialog.open) {
            dialog.close()
        } else {
            dialog.showModal()
        }
    }

    const onLoad = () => {
        if (!imgRef.current || !dialogRef.current) return
        const containerWidth = dialogRef.current.offsetWidth
        const containerHeight = dialogRef.current.offsetHeight

        const imageWidth = imgRef.current.naturalWidth
        const imageHeight = imgRef.current.naturalHeight

        const zoom =
            Math.round(Math.min(containerWidth / imageWidth, containerHeight / imageHeight) / ZOOM_INCREMENT) *
            ZOOM_INCREMENT
        const closestZoom = Math.max(Math.min(zoom, 1.0), MIN_ZOOM)

        viewer.current.setZoom(closestZoom)
        viewer.current.scrollCenter()
        setLightZoom(closestZoom)
    }

    const getImage = useCallback(async () => {
        const { data } = await apiService.getCommentFile(commentKey)
        const img = URL.createObjectURL(data)

        const imgElement = new Image()

        imgElement.onload = () => {
            if (imgRef.current) {
                imgRef.current.src = img
            }

            setLoading(false)
        }

        imgElement.src = img
    }, [commentKey])

    useEffect(() => {
        getImage()
    }, [commentKey])

    useEffect(() => {
        viewer.current.setZoom(lightZoom)
    }, [lightZoom])

    return (
        <>
            <button className='btn btn-sm btn-neutral w-fit' onClick={handlePress}>
                <AttachedImageIcon />
                Ver anexo
            </button>
            <dialog ref={dialogRef} className='modal bg-[#0e1729]/80' onClick={() => handlePress({} as any)}>
                <div
                    className='modal-box w-full h-full max-h-[90%] max-w-[90%] relative'
                    onClick={(e) => e.stopPropagation()}
                >
                    <ButtonClose onClick={handlePress} />
                    <div className='dialog w-full h-full'>
                        <Loading isLoading={loading}>
                            <div className='flex flex-col w-full h-full justify-between'>
                                <InfiniteViewer
                                    displayVerticalScroll={false}
                                    displayHorizontalScroll={false}
                                    useMouseDrag={true}
                                    ref={viewer}
                                    className='w-full flex-grow rounded-md'
                                >
                                    <div className='w-fit'>
                                        <img ref={imgRef} onLoad={onLoad} />
                                    </div>
                                </InfiniteViewer>

                                <div className='flex flex-col justify-center items-center gap-4'>
                                    <LightZoom />
                                    <p className='p-4 rounded-lg bg-black/30 w-full'>{commentText}</p>
                                </div>
                            </div>
                        </Loading>
                    </div>
                </div>
            </dialog>
        </>
    )
}
