import React, { useEffect } from 'react'
import Message from '../../../components/message'
import { useRecoilState, useRecoilValue } from 'recoil'
import { allTagsState, router, selectedTagsState } from '../../../AppState'
import classNames from 'classnames'
import { useTagManager } from '@src/utils/tags'
import Menus from './menus'

const TagsList = () => {
    const allTags = useRecoilValue(allTagsState)
    const [selectedTags, setSelectedTags] = useRecoilState(selectedTagsState)
    const tagManager = useTagManager(allTags || [])

    const handleSelectTag = (tag: string, e: React.MouseEvent<HTMLSpanElement>) => {
        if (e.altKey) return

        const updatedTags = new Set(selectedTags)

        if (!updatedTags.delete(tag)) updatedTags.add(tag)
        const s = Array.from(updatedTags)
        setSelectedTags(updatedTags)

        router.history.push(router.history.location.pathname + '?' + 'tags=' + s.join(','))
    }

    const handleUncheckTags = () => {
        setSelectedTags(new Set())
        router.history.push(router.history.location.pathname)
    }

    useEffect(() => {
        const tags = router.history.location.search?.replace('?tags=', '')
        const splittedTags = tags
            .split(',')
            .filter((t) => t !== '')
            .map((t) => decodeURI(t))
        setSelectedTags(new Set(splittedTags))
    }, [])

    return (
        <>
            <Menus tags={tagManager.namespacedTags} />
            {tagManager.tags.length > 1 && (
                <span
                    className='cursor-pointer badge m-1 badge-sm h-min badge-secondary badge-outline'
                    onClick={handleUncheckTags}
                >
                    Desmarcar todos
                </span>
            )}
            {allTags ? (
                [...tagManager.tags]
                    .sort((a, b) => a.localeCompare(b))
                    .map((tag) => (
                        <span
                            tabIndex={0}
                            key={tag}
                            className={classNames('cursor-pointer badge badge-primary h-min badge-sm mt-1 ml-1', {
                                'badge-outline': !selectedTags.has(tag),
                            })}
                            onClick={handleSelectTag.bind(null, tag)}
                        >
                            {tag}
                        </span>
                    ))
            ) : (
                <Message text='Nenhuma tag cadastrada para a campanha.' className='h-8 p-2' />
            )}
        </>
    )
}

export default TagsList
