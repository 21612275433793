import { useEffect, useState } from "react";

export const useDetectAdBlock = () => {
  const [adBlockDetected, setAdBlockDetected] = useState(false);

  useEffect(() => {
    const url = "https://www3.doubleclick.net";
    fetch(url, {
      method: "HEAD",
      mode: "no-cors",
      cache: "no-store",
    })
      .then(({ redirected }) => {
        if (redirected) setAdBlockDetected(true);
      })
      .catch(() => {
        setAdBlockDetected(true);
      });
  }, []);

  return adBlockDetected;
};