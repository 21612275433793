import React, { useEffect, useRef, useState } from 'react'
import InfiniteViewer from 'react-infinite-viewer'

import { useRecoilState } from 'recoil'
import { PlayerStatus, zoomState } from '../AppState'
import PreviewGifControls from './preview-gif.control'
import classNames from 'classnames'
import useToggleLayout from '../utils/useToggleLayout/use.toggle-layout'
import CarrouselButton from '../components/carrousel-button/carrousel-button'
import { MIN_ZOOM, ZOOM_DICTIONARY, ZOOM_INCREMENT } from '../utils/zoom'
import { SuperGif } from '@wizpanda/super-gif'
import { usePreview } from '@src/contexts/usePreview/use.preview'
import Loading from '@src/components/loading'
import { twMerge } from 'tailwind-merge'
import { usePlayerUtils } from './usePlayerUtils'

if (!(SuperGif.prototype as any).oldPutFrame) {
    const oldPutFrame = (SuperGif.prototype as any).putFrame as any
    ;(SuperGif.prototype as any).oldPutFrame = oldPutFrame
    ;(SuperGif.prototype as any).putFrame = function () {
        this.oldPutFrame()

        if (this.onUpdate) this.onUpdate(this.currentFrameIndex)
    }
}

type Props = {
    gif: string
}

const PreviewGif = ({ gif }: Props) => {
    const [zoom, setZoom] = useRecoilState(zoomState)
    const { isHovering, setIsHovering, isDragging, dragEvents } = usePlayerUtils()
    const [gifRef, setGifRef] = useState<any>(undefined)
    const [playerStatusValue, setPlayerStatus] = useRecoilState(PlayerStatus)
    const rub = useRef<SuperGif | null>(null)
    const imageRef = useRef<HTMLImageElement | null>(null)
    const containerRef = useRef<HTMLDivElement | null>(null)
    const viewer = useRef<InfiniteViewer>({} as InfiniteViewer)
    const { useGrid } = useToggleLayout()
    const { createAnnotationFile, loading, downloadProgress } = usePreview()

    const onLoad = () => {
        if (!imageRef.current || !containerRef.current || !rub.current) return

        try {
            const containerWidth = containerRef.current.offsetWidth
            const containerHeight = containerRef.current.offsetHeight

            const { width: imageWidth, height: imageHeight } = rub.current.getCanvas()

            const zoom =
                Math.round(Math.min(containerWidth / imageWidth, containerHeight / imageHeight) / ZOOM_INCREMENT) *
                ZOOM_INCREMENT
            const closestZoom = Math.max(Math.min(zoom, 1.0), MIN_ZOOM) // não deixa o inicial ser maior que 1.0

            viewer.current.setZoom(closestZoom)
            viewer.current.scrollCenter()
            setZoom(closestZoom)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (gif && !rub.current && imageRef.current) {
            rub.current = new SuperGif(imageRef.current, {
                autoPlay: false,
                loop: true,
            })

            rub.current.load(function () {
                let tempRub: SuperGif

                if (rub.current) tempRub = rub.current
                else return

                setGifRef(rub.current)

                setPlayerStatus({
                    isPlaying: true,
                    time: 0,
                    totalTime: tempRub.getLength() - 1,
                })
                ;(rub.current as any).onUpdate = (frame: number) => {
                    setPlayerStatus((value) => {
                        return {
                            ...value,
                            time: frame,
                        }
                    })

                    if (frame === tempRub.getLength() - 1) {
                        setPlayerStatus((value) => {
                            return {
                                ...value,
                                isPlaying: false,
                            }
                        })
                        tempRub.pause()
                    }
                }

                onLoad()
            })
        }
    }, [gif])

    useEffect(() => {
        viewer.current.setZoom(zoom)
        viewer.current.scrollCenter()
    }, [zoom, containerRef, gif])

    useEffect(() => {
        if (!playerStatusValue.isPlaying && rub.current?.isReady?.()) {
            createAnnotationFile('GIF', gif, playerStatusValue.time)
        }
    }, [playerStatusValue.time, playerStatusValue.isPlaying])

    useEffect(() => {
        return () => {
            rub.current = null
            createAnnotationFile('NULL')
            setPlayerStatus({
                isPlaying: false,
                time: 0,
                totalTime: 0,
            })
        }
    }, [])

    return (
        <Loading
            isLoading={loading || !gifRef}
            progress={downloadProgress}
            className={classNames('bg-base-300', {
                'absolute top-0 left-0 w-full h-[calc(95vh-17rem)] z-[999]': useGrid,
                'absolute top-0 left-0 w-full h-full rounded-md z-[99]': !useGrid,
            })}
        >
            <div
                className={classNames('w-full grow flex items-center justify-center relative', {
                    'h-[calc(95vh-17rem)]': useGrid,
                    'h-full': !useGrid,
                })}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
                ref={containerRef}
            >
                <CarrouselButton>
                    <InfiniteViewer ref={viewer} className='w-full h-full bg-base-300' {...dragEvents}>
                        <div className='w-fit'>
                            <img src={gif} ref={imageRef} onLoad={onLoad} />
                        </div>
                    </InfiniteViewer>
                </CarrouselButton>

                <div
                    className={twMerge(
                        'absolute bottom-0 left-0 w-full bg-base-300 bg-opacity-80',
                        isHovering && !isDragging ? 'block' : 'hidden'
                    )}
                >
                    <PreviewGifControls gifRef={gifRef} handleCenterPreview={onLoad} />
                </div>
            </div>
        </Loading>
    )
}

export default PreviewGif
