import React from 'react'

import { usePreview } from '@src/contexts/usePreview/use.preview'
import Loading from '@src/components/loading'
import classNames from 'classnames'
import useToggleLayout from '@src/utils/useToggleLayout'

const Preview = () => {
    const { displayByType, downloadProgress } = usePreview()
    const { useGrid } = useToggleLayout()

    return (
        <div className='flex flex-col w-full h-full relative rounded-lg overflow-hidden'>
            <Loading
                isLoading={displayByType === null}
                progress={downloadProgress}
                className={classNames('bg-base-300', {
                    'absolute top-0 left-0 w-full h-[calc(95vh-17rem)] z-[999]': useGrid,
                    'absolute top-0 left-0 w-full h-full rounded-md z-[99]': !useGrid,
                })}
            >
                {displayByType}
            </Loading>
        </div>
    )
}

export default Preview
