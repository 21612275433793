import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { IoMdAlert } from 'react-icons/io'
import { MdClose } from 'react-icons/md'
import { useDetectAdBlock } from '@src/utils/AdblockDetection/useAdblockDetecion'

export const useToastAlertAdblock = () => {
    const adblockDetected = useDetectAdBlock()

    useEffect(() => {
        if (adblockDetected) {
            toast(
                (t) => (
                    <div className='flex items-center justify-between'>
                        <IoMdAlert className='mr-4 text-yellow-400 h-16 w-16' />
                        <p className='text-yellow-700'>
                            <b>AdBlock</b> detectado! Por favor, desative para melhor experiência.
                        </p>
                        <button className='absolute right-0 top-0 p-2' onClick={() => toast.dismiss(t.id)}>
                            <MdClose size={20} />
                        </button>
                    </div>
                ),
                {
                    duration: Infinity,
                    position: 'top-right',
                    style: {
                        padding: 2,
                        margin: 2,
                        backgroundColor: '#fefce8',
                    },
                }
            )
        }
    }, [adblockDetected])

    return { isBlocking: adblockDetected }
}
