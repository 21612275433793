import React, { useState } from 'react'
import TextField from '../../text-field/text-field'

import { useSetRecoilState } from 'recoil'
import { campaignsListState } from '../../../AppState'
import { campaignOptions } from '../../../services/campaign.service'
import classNames from 'classnames'
import toast from 'react-hot-toast'
import { FormAddCampaign } from '../../../types'

const CreateCampaignDialog = () => {
    const { onCreateCampaign, onGetCampaignList } = campaignOptions
    const [loading, setLoading] = useState<boolean>(false)
    const [showDialog, setShowDialog] = useState<boolean>(false)
    const [campaignForm, setCampaignForm] = useState<FormAddCampaign>({} as FormAddCampaign)
    const setCampaignList = useSetRecoilState(campaignsListState)

    const handleOpenDialog = () => {
        setShowDialog(true)
    }

    const handleCloseDialog = () => {
        setShowDialog(false)
    }

    const handleCreateCampaign = () => {
        setLoading(true)
        const myPromise = onCreateCampaign(campaignForm)
        toast
            .promise(myPromise, {
                loading: 'Criando campanha...',
                success: 'Campanha criada com sucesso!',
                error: 'Erro ao criar campanha.',
            })
            .finally(async () => {
                const updatedList = await onGetCampaignList()
                setCampaignList(updatedList)
                setCampaignForm({} as FormAddCampaign) 
                setLoading(false)
                handleCloseDialog()
            })
    }

    return (
        <>
            <button className={classNames('btn btn-neutral btn-xs sm:btn-sm')} onClick={handleOpenDialog}>
                Adicionar
            </button>

            {showDialog && (
                <div className='modal modal-open modal-bottom sm:modal-middle bg-slate-800/90 '>
                    <div className='modal-box'>
                        <h3 className='font-bold text-lg'>Nova campanha:</h3>
                        <button
                            className={classNames('btn btn-neutral btn-sm btn-circle absolute right-4 top-4', {
                                'loading cursor-wait:': loading,
                            })}
                            onClick={handleCloseDialog}
                        >
                            { !loading && 'x' }
                        </button>

                        <TextField
                            label='Cliente'
                            name='client'
                            placeholder='Apple'
                            value={campaignForm.client}
                            readOnly={loading}
                            onStateChange={setCampaignForm}
                        />

                        <TextField
                            label='Nome'
                            name='name'
                            placeholder='Minha campanha'
                            value={campaignForm.name}
                            readOnly={loading}
                            onStateChange={setCampaignForm}
                        />

                        <TextField
                            label='Link do Google Drive'
                            name='url'
                            placeholder='https://drive.google.com/drive/u/0/folders/sample_folder'
                            value={campaignForm.url}
                            readOnly={loading}
                            onStateChange={setCampaignForm}
                        />

                        <TextField
                            label='Webhook - Chat Bot (Opcional)'
                            name='webhook'
                            placeholder='https://chat.googleapis.com/v1/spaces/...'
                            value={campaignForm.webhook}
                            readOnly={loading}
                            onStateChange={setCampaignForm}
                        />

                        <div className='modal-action'>
                            <button
                                onClick={handleCloseDialog}
                                disabled={loading}
                                className={classNames('btn btn-neutral', {
                                    'loading cursor-wait': loading,
                                })}
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleCreateCampaign}
                                disabled={loading}
                                className={classNames('btn btn-primary', {
                                    'loading cursor-wait': loading,
                                })}
                            >
                                Adicionar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default CreateCampaignDialog
