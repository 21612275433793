import React, { useEffect, useMemo, useState } from 'react'
import TableHeader from './table-header'
import TableBody from './table-body/table-body'
import classNames from 'classnames'
import { filteredCampaignListSelector } from '@src/AppState'
import { useRecoilValue } from 'recoil'
import { PaginationFrame } from '@src/components/PaginationFrame'

const perPage = 30

const CampaignList = () => {
    const campaigns = useRecoilValue(filteredCampaignListSelector)

    const [currentPage, setCurrentPage] = useState<number>(1)

    const paginationFrame = useMemo(
        () => campaigns.slice((currentPage - 1) * perPage, currentPage * perPage),
        [currentPage, campaigns]
    )

    useEffect(() => {
        setCurrentPage(1)
    }, [campaigns])

    return (
        <div className={classNames('overflow-x-auto w-full mx-auto h-full', {})}>
            <table className='table table-pin-rows table-pin-cols w-full rounded-md bg-base-400'>
                <TableHeader />
                <TableBody campaigns={paginationFrame} />
            </table>

            <PaginationFrame
                totalItems={campaigns.length}
                currentPage={currentPage}
                perPage={perPage}
                handleNextPage={() => setCurrentPage((prev) => prev + 1)}
                handlePreviousPage={() => setCurrentPage((prev) => prev - 1)}
                handleGoToPage={setCurrentPage}
            />
        </div>
    )
}

export default CampaignList
