import React, { ComponentProps } from 'react'

import CommentSubmit from '../../../comments/comment-submit'
import CreativeStatus from '../../../creative/status'
import Preview from '../../../preview'

import { useRecoilValue } from 'recoil'
import { CampaignDetails, fullscreenState, isGridLayout } from '../../../AppState'
import prettyBytes from 'pretty-bytes'
import classNames from 'classnames'

import Truncate from 'react-truncate'

import { twMerge } from 'tailwind-merge'
import AddNoteDialog from '@src/components/dialogs/add-note-dialog'
import { usePreview } from '@src/contexts/usePreview/use.preview'

type Props = {
    className: ComponentProps<'div'>['className']
}

const CreativePreview = ({ className }: Props) => {
    const fullscreen = useRecoilValue(fullscreenState)
    const campaign = useRecoilValue(CampaignDetails)
    const isGrid = useRecoilValue(isGridLayout)

    const { creative, loading } = usePreview()

    if (!creative) return null

    const { name, size } = creative
    const prettySize = prettyBytes(size, { locale: 'pt-BR', binary: true })

    return (
        <div
            className={twMerge(
                'flex flex-col gap-y-2 w-full h-full p-4 overflow-hidden rounded-md',
                !isGrid && 'py-0',
                className
            )}
        >
            <div
                className={classNames('flex flex-col h-full', {
                    'w-full absolute top-0 left-0 z-[999] p-4 bg-base-300': fullscreen,
                })}
            >
                <div className='flex justify-between items-center pl-2 mb-4'>
                    <h3 className='font-bold text-xl truncate w-4/6 text-info'>
                        <Truncate lines={1} ellipsis={<span>...</span>}>
                            {name}
                        </Truncate>
                        <span className='sr-only'>{name}</span>
                    </h3>
                    <span className='badge badge-info max-w-none'>{prettySize}</span>
                </div>
                <div className='w-full h-full relative'>
                    <div className='absolute z-[9] left-2 top-2'>{!loading && <AddNoteDialog />}</div>

                    <Preview />
                </div>
            </div>
            <div className='flex space-x-2 items-center pt-4 border-t-2 border-zinc-800 h-auto'>
                <CreativeStatus />
                <CommentSubmit campaignKey={campaign.key} creative={creative} />
            </div>
        </div>
    )
}

export default CreativePreview
