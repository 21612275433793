import React from 'react'
import CampaignOptions from '../../campaign-options/campaign-options'

import { router } from '../../../AppState'
import dayjs from 'dayjs'
import { Campaign } from '@src/types'

type TableBodyProps = {
    campaigns: Campaign[]
}

const TableBody = ({ campaigns }: TableBodyProps) => {
    const isEmpty = (str: string) => {
        return str?.trim() === ''
    }

    const displayLastDate = (date: string, message: string): string => {
        try {
            if (isEmpty(date)) {
                return message
            } else {
                return dayjs(date).locale('pt-br').fromNow()
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <tbody>
            {campaigns.length === 0 ? (
                <tr>
                    <td colSpan={5} className='text-center'>
                        Nenhuma campanha encontrada.
                    </td>
                </tr>
            ) : (
                campaigns.map((campaign: Campaign, index: number) => (
                    <tr key={`${index} - ${campaign.key}`} className='even:bg-base-200'>
                        <td className='max-sm:hidden'>{campaign.client}</td>
                        <td>
                            <button
                                className='btn-quiet transition hover:text-cyan-500 max-w-[232px] max-sm:max-w-[124px] truncate'
                                onClick={() => router.history.push(`${campaign.url}/${campaign.key}`)}
                                data-tooltip-id='my-tooltip'
                                data-tooltip-html='Visualizar campanha'
                                data-tooltip-place='top'
                            >
                                {campaign.name}
                            </button>
                        </td>
                        <td className='max-sm:hidden'>{displayLastDate(campaign.updatedAt, 'Nenhuma atualização')}</td>
                        <td className='max-sm:hidden'>{displayLastDate(campaign.createdAt, 'Nenhum comentário')}</td>
                        <td>
                            <CampaignOptions campaign={campaign} />
                        </td>
                    </tr>
                ))
            )}
        </tbody>
    )
}

export default TableBody
