import React, { useState, forwardRef } from 'react'

import toast from 'react-hot-toast'
import { campaignOptions } from '../../../services/campaign.service'
import { Campaign, FormEditCampaign } from '../../../types'
import { useSetRecoilState } from 'recoil'
import { campaignsListState } from '../../../AppState'
import TextField from '../../text-field/text-field'
import ButtonClose from '../button-close.dialog'

type Props = {
    campaign: Campaign
}

const EditCampaignDialog = ({ campaign }: Props, ref: React.LegacyRef<HTMLDialogElement> | null) => {
    const [campaignForm, setCampaignForm] = useState<FormEditCampaign>({
        client: campaign.client,
        name: campaign.name,
        webhook: campaign.webhook || '',
    })

    const { onEditCampaign, onGetCampaignList } = campaignOptions
    const setCampaigns = useSetRecoilState(campaignsListState)

    const handleEditCampaign = () => {
        const myPromise = onEditCampaign(campaign.key, campaignForm)
        toast.promise(myPromise, {
            loading: 'Salvando alterações...',
            success: 'Alterações salvas com sucesso!',
            error: 'Erro ao salvar alterações..',
        }).finally(async () => {
            const updatedList = await onGetCampaignList()
            setCampaigns(updatedList)
        })
    }

    return (
        <dialog className='modal' ref={ref}>
            <div className='modal-box w-[50rem] max-w-none h-auto overflow-y-auto rounded-md'>
                <form method='dialog' className='flex flex-col gap-y-4'>
                    <ButtonClose />

                    <h3 className='font-bold text-lg'> Editar campanha </h3>

                    <TextField
                        label='Cliente'
                        name='client'
                        onStateChange={setCampaignForm}
                        value={campaignForm.client}
                        placeholder='Ex: Apple'
                    />

                    <TextField
                        label='Nome da campanha'
                        name='name'
                        onStateChange={setCampaignForm}
                        value={campaignForm.name}
                        placeholder='Ex: Minha campanha'
                    />

                    <TextField
                        label='Webhook - Chat Bot (Opcional)'
                        name='webhook'
                        placeholder='https://chat.googleapis.com/v1/spaces/...'
                        onStateChange={setCampaignForm}
                        value={campaignForm.webhook}
                    />

                    <div className='bg-base-100 sm:rounded-box sm:py-4 sm:px-0 grow'>
                        <div className='flex justify-end space-x-4'>
                            <button className='btn btn-neutral'>
                                Cancelar
                            </button>
                            <button className='btn btn-primary' onClick={handleEditCampaign}>
                                Confirmar
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            <form method="dialog" className="flex justify-end">
                <button>close</button>
            </form>
        </dialog>
    )
}

export default forwardRef(EditCampaignDialog)
