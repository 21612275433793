import React, { useEffect } from 'react'
import { useRef, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { currentCreative, PlayerStatus, fullscreenState } from '../AppState'
import CreativeSelectRevision from '../creative/select-revision'
import { formatTime } from '../utils/time'
import PreviewTimeBarControl from './time-bar-control'
import CreativeZoom from '@src/creative/zoom'

type Props = {
    videoRef?: any
}

const PreviewVideoControls = ({ videoRef }: Props) => {
    const creative = useRecoilValue(currentCreative)
    const [fullscreen, setFullscreen] = useRecoilState(fullscreenState)
    const [{ time, isPlaying, totalTime }, setPlayerStatus] = useRecoilState(PlayerStatus)
    const shouldStop = useRef(false)
    const shouldRestart = useRef(false)
    const isDestroyed = useRef(false)
    const start = useRef<number | undefined>(0)
    const [actualTime, setActualTime] = useState(0)
    const [isMuted, setIsMuted] = useState(true)

    const onPlay = (timestamp: number) => {
        if (isDestroyed.current) return

        if (!start.current) {
            start.current = timestamp
            videoRef.play()
        }

        let progress = timestamp - start.current

        let newTime = actualTime >= totalTime ? progress : actualTime + progress

        if (newTime >= totalTime) {
            newTime = totalTime
        }

        if (!shouldStop.current && !shouldRestart.current) {
            if (progress === 0)
                setPlayerStatus((state: any) => {
                    return {
                        ...state,
                        isPlaying: true,
                    }
                })

            if (newTime >= totalTime) {
                shouldStop.current = true
                shouldRestart.current = true
            }

            setActualTime(() => {
                return newTime
            })

            window.requestAnimationFrame(onPlay)
        } else if (shouldRestart.current) {
            onRestart()
        } else {
            onPause(newTime)
        }
    }

    const onPause = (time: number) => {
        start.current = undefined
        shouldStop.current = false
        shouldRestart.current = false

        videoRef.pause()

        setPlayerStatus((state: any) => {
            return {
                ...state,
                isPlaying: false,
                time,
            }
        })
    }

    const resetPlayer = () => {
        start.current = undefined
        shouldStop.current = false
        shouldRestart.current = false

        videoRef.pause()
        videoRef.currentTime = 0
        setActualTime(0)
    }

    const onRestart = () => {
        if (videoRef)
            resetPlayer()

        setPlayerStatus((state: any) => {
            return {
                ...state,
                isPlaying: false,
                time: 0,
            }
        })
    }

    const handlePause = () => {
        shouldStop.current = true
    }

    const playPauseHandle = () => {
        if (!isPlaying) window.requestAnimationFrame(onPlay)
        else handlePause()
    }

    const restartHandle = () => {
        shouldRestart.current = true

        if (!start.current) onRestart()
    }

    const handleFullscreen = () => {
        setFullscreen((value) => !value)
    }

    const soundHandle = () => {
        setIsMuted((value) => {
            videoRef.muted = !value
            return !value
        })
    }

    useEffect(() => {
        if (videoRef) {
            videoRef.currentTime = time / 1000
            setActualTime(() => {
                return time
            })
        }
    }, [time])

    useEffect(() => {
        if (videoRef) {
            isDestroyed.current = false
            onPlay(0)
            videoRef.onvolumechange = (_event: any) => {
                setIsMuted(videoRef.muted)
            }
        }

        return () => {
            if (videoRef) {
                isDestroyed.current = true
                resetPlayer()
            }
        }
    }, [videoRef])

    useEffect(() => {
        return () => {
            setFullscreen(false)
            onRestart()
        }
    }, [])

    return (
        <>
            <PreviewTimeBarControl actualTime={actualTime} />
            <div className='w-full grow flex flex-row items-center space-between pt-3'>
                <div className='w-full grow flex flex-row items-center justify-start gap-x-5'>
                    <button className='btn p-1 btn-sm' onClick={playPauseHandle}>
                        {isPlaying ? (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-5 h-5'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M15.75 5.25v13.5m-7.5-13.5v13.5'
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-5 h-5'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z'
                                />
                            </svg>
                        )}
                    </button>
                    <button className='btn p-1 btn-sm' onClick={soundHandle}>
                        {isMuted ? (
                            <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
                                <title>S VolumeMute 18 N</title>
                                <rect id='Canvas' fill='currentColor' opacity='0' width='18' height='18' />
                                <path
                                    fill='currentColor'
                                    d='M6,13.5A5.4915,5.4915,0,0,1,8,9.261V2.5a.363.363,0,0,0-.597-.2855l-3.3195,3.4c-.2195.2235-.363.4225-.711.4225H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.3725c.35,0,.5.2055.711.4225l2.0025,2.05A5.50725,5.50725,0,0,1,6,13.5Z'
                                />
                                <path
                                    fill='currentColor'
                                    d='M11.5,9.05a4.45,4.45,0,1,0,4.45,4.45A4.45,4.45,0,0,0,11.5,9.05ZM8,13.5a3.46455,3.46455,0,0,1,.7375-2.126l4.8885,4.8885A3.483,3.483,0,0,1,8,13.5Zm6.2625,2.126L9.374,10.7375a3.483,3.483,0,0,1,4.8885,4.8885Z'
                                />
                            </svg>
                        ) : (
                            <svg xmlns='http://www.w3.org/2000/svg' height='18' viewBox='0 0 18 18' width='18'>
                                <title>S VolumeThree 18 N</title>
                                <rect id='Canvas' fill='currentColor' opacity='0' width='18' height='18' />
                                <path
                                    fill='currentColor'
                                    d='M3.3725,6.0365H.5a.5.5,0,0,0-.5.5V11.5a.5.5,0,0,0,.5.5H3.3725a.714.714,0,0,1,.4655.1725L7.403,15.802A.3635.3635,0,0,0,8,15.5145V2.5a.363.363,0,0,0-.597-.2855l-3.5635,3.65A.72.72,0,0,1,3.3725,6.0365Z'
                                />
                                <path
                                    fill='currentColor'
                                    d='M11.02,9a3.46751,3.46751,0,0,1-.7035,2.096.49.49,0,0,0,.043.644l.008.008a.496.496,0,0,0,.7435-.045A4.47751,4.47751,0,0,0,11.1,6.2765a.496.496,0,0,0-.742-.0435l-.0075.008a.491.491,0,0,0-.0425.646A3.47151,3.47151,0,0,1,11.02,9Z'
                                />
                                <path
                                    fill='currentColor'
                                    d='M14.02,9a6.469,6.469,0,0,1-1.5575,4.2175.4865.4865,0,0,0,.0315.6585l.007.007a.5.5,0,0,0,.70711-.00167q.01566-.01574.02989-.03283a7.49,7.49,0,0,0-.013-9.7145.5.5,0,0,0-.70443-.0614q-.01558.01308-.03007.0274l-.007.0075a.48849.48849,0,0,0-.0335.6595A6.46848,6.46848,0,0,1,14.02,9Z'
                                />
                                <path
                                    fill='currentColor'
                                    d='M17.02,9a9.46,9.46,0,0,1-2.4115,6.321.5.5,0,0,0,.012.6875l.007.0075a.491.491,0,0,0,.69436.00569l.01664-.01719A10.43247,10.43247,0,0,0,17.9915,9a10.43548,10.43548,0,0,0-2.663-7.0175.4925.4925,0,0,0-.696-.02553l-.016.01553-.0075.007a.5.5,0,0,0-.01.6875A9.461,9.461,0,0,1,17.02,9Z'
                                />
                            </svg>
                        )}
                    </button>
                    <button className='btn p-1 btn-sm' onClick={restartHandle}>
                        <svg
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            strokeWidth={1.5}
                            stroke='currentColor'
                            className='w-5 h-5'
                        >
                            <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99'
                            />
                        </svg>
                    </button>
                    <p className='tabular-nums text-sm whitespace-nowrap'>
                        {formatTime(actualTime)} / {formatTime(totalTime)}
                    </p>
                </div>
                <div className={`w-full grow flex flex-row items-center justify-end gap-x-5 ml-5`}>
                    <CreativeZoom />
                    <button className='btn p-1 btn-sm' onClick={handleFullscreen}>
                        {fullscreen ? (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-6 h-6'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M9 9V4.5M9 9H4.5M9 9L3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5l5.25 5.25'
                                />
                            </svg>
                        ) : (
                            <svg
                                xmlns='http://www.w3.org/2000/svg'
                                fill='none'
                                viewBox='0 0 24 24'
                                strokeWidth={1.5}
                                stroke='currentColor'
                                className='w-6 h-6'
                            >
                                <path
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    d='M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15'
                                />
                            </svg>
                        )}
                    </button>
                    {(creative?.revisions?.length || 0) <= 1 ? <></> : <CreativeSelectRevision />}
                </div>
            </div>
        </>
    )
}

export default PreviewVideoControls
