import React, { useState } from 'react'
import { FaChevronDown as DownIcon, FaChevronUp as UpIcon } from 'react-icons/fa'
import { SlOptionsVertical as OptionIcon } from 'react-icons/sl'
import classNames from 'classnames'

type PanelOption = {
    title: string
    action: () => void
}

type PanelProps = {
    children: JSX.Element
    title: string
    isMinimizable?: boolean
    options?: PanelOption[]
}

export default function Panel({ children, title, isMinimizable = false, options = [] }: PanelProps) {
    const [show, setShow] = useState(true)

    const handleOpen = () => {
        setShow((current) => !current)
    }

    return (
        <section
            className={classNames('flex-1 flex h-full flex-col bg-[#090F1D] rounded-xl overflow-hidden', {
                'flex-initial h-fit': isMinimizable,
            })}
        >
            <header
                className={classNames(
                    'flex justify-between items-center text-lg px-4 py-2 bg-[#070B17] font-[500] text-white h-[48px]',
                    {
                        'rounded-xl': !show,
                    }
                )}
            >
                <span className='truncate'>{title}</span>
                {isMinimizable && (
                    <button className='btn btn-ghost btn-sm' onClick={handleOpen}>
                        {show ? <UpIcon /> : <DownIcon />}
                    </button>
                )}
                {options.length > 0 && (
                    <div className='dropdown dropdown-left dropdown-start'>
                        <div tabIndex={0} role='button' className='btn btn-sm btn-ghost'>
                            <OptionIcon />
                        </div>
                        <ul
                            tabIndex={0}
                            className='dropdown-content z-[99] menu p-2 shadow bg-base-100 rounded-box min-w-max'
                        >
                            {options.map(option => <li key={option.title} onClick={option.action}><a>{option.title}</a></li>)}
                        </ul>
                    </div>
                )}
            </header>
            {show && <div className='w-full relative h-[calc(100%-48px)] overflow-y-auto'>{children}</div>}
        </section>
    )
}
